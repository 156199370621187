import { Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";

export default function DashboardsPageComponent() {
	return <>
		<Typography
			style={{
				fontSize: 140,
				textAlign: "center"
			}}
			variant="h1"
		>
			Dashboards
		</Typography>
		<Typography
			style={{
				fontSize: 140,
				textAlign: "center"
			}}
		>
			<Link
				href="/admin/dashboards/key-performance-indicators">
				KPI's
			</Link>
		</Typography>
		<br />
		<Typography
			style={{
				fontSize: 140,
				textAlign: "center"
			}}
		>
			<Link
				href="/admin/dashboards/session-frequencies">
				Session Frequencies
			</Link>
		</Typography>
		<br />
		<Typography
			style={{
				fontSize: 140,
				textAlign: "center"
			}}
		>
			<Link
				href="/admin/dashboards/sessions">
				Sessions
			</Link>
		</Typography>
		<br />
		<Typography
			style={{
				fontSize: 140,
				textAlign: "center"
			}}
		>
			<Link
				href="/admin/dashboards/shipping">
				Shipping
			</Link>
		</Typography>
	</>
};